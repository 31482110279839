.main {
  margin: 0 20px;
}
.main > img {
  max-width: 100%;
  margin: 30px 0 15px;
}
.main > h5 {
  margin-bottom: 15px;
}
.uploadDetails {
  border-top: 1px solid var(--shadow-grey);
  padding-top: 15px;
  margin-top: 15px;
  font-size: 12px;
}
