.quizForm > button {
  margin-left: 10px;
  margin-top: 8px;
}
.quizQuestions {
  margin: 20px 0 35px;
}
.quizQuestions > div > h4 {
  margin: 15px 0;
}
.quizQuestions > div > button {
  margin-top: 10px;
}
.ageInp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  text-align: left;
  gap: 30px;
}
.ageInp label {
  margin-bottom: 6px;
  display: block;
}
.ageInp > div:first-child > select {
  width: 100px;
}
.shuffle {
  margin-top: 13px;
}
.shuffle > span:first-child {
  margin-right: 15px;
}
.shuffle > span > input {
  margin-right: 3px;
}
