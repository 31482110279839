.mainDiv {
  z-index: 300;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(41, 41, 41, 0.664);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainDiv > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  max-width: 340px;
  background-color: white;
  padding: 30px 25px;
  border-radius: 5px;
  color: #636363;
}
.crossIcon {
  color: #ff225d;
  font-size: 80px;
  font-weight: 400;
  margin-bottom: 30px;
}
.mainDiv > div > h3 {
  font-weight: 500;
}
.mainDiv > div > p {
  text-align: center;
  margin: 20px 0;
}
.btnDiv {
  margin-top: 10px;
}
.btnDiv > button {
  border-radius: 3px;
  padding: 10px 25px;
  color: #ffffff;
}
.btnDiv > button:first-child {
  background-color: #c1c1c1;
  margin-right: 13px;
}
.btnDiv > button:last-child {
  background-color: #ff225d;
}
