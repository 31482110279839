.header {
  height: 70px;
}
.header > main {
  background-color: var(--brown);
  z-index: 1;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding: 0 20px 0 0;
  box-shadow: 0 3px 10px 0 #a1a1a1;
}
.header > main > div {
  background-color: var(--lightgray);
  padding-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--gray);
  padding-right: 17px;
}
.header > main > div > img {
  width: 58px;
  height: 58px;
}
.header > main > div > div {
  margin-top: -5px;
  margin-left: 10px;
}
.header > main > div > div > h3 {
  font-weight: 500;
  font-size: 28px;
  color: #282c34;
}
.header > main > div > div > h5 {
  margin-top: -10px;
  font-weight: 500;
}
.dropdown {
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 !important;
  margin: 11.75px 0;
}
.dropdown > a {
  border-radius: 5px;
  padding: 12px;
}
.dropdown a h5 {
  display: inline-block;
  color: black;
  font-size: 15.5px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 110px;
  margin-bottom: -10px;
}
.dropdown a img {
  margin-right: 10px;
}

/* media query */

@media only screen and (max-width: 768px) {
  .header > main > div {
    padding-left: 5px;
    padding-right: 5px;
  }
  .header > main > div > div {
    display: none;
  }
}
