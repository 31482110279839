.heading {
  color: var(--brown) !important;
  font-weight: 500;
}

.selection,
.inputField {
  border-radius: 4px;
  border: 1px solid grey;
  padding: 5px 8px;
  width: 250px;
}

.selection {
  padding: 5px;
  cursor: pointer;
}

.btn {
  margin-top: 5px;
  width: 250px;
  padding: 5px 8px;
  border-radius: 4px;
}

.dataTable {
  width: 100%;
  margin: 20px 0;
  box-shadow: 0 5px 10px 0 #0000005e;
}
.datatableRow > td {
  white-space: nowrap;
}

.dataTable thead tr {
  background-color: #ffffff;
}

.dataTable thead tr:hover {
  background-color: #ececec;
}

.dataTable tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.dataTable tbody tr:nth-child(even):hover {
  background-color: #ececec;
}

.dataTable tbody tr:nth-child(odd) {
  background-color: #dddddd;
}

.dataTable tbody tr:nth-child(odd):hover {
  background-color: #cccccc;
}

.dataTable thead tr th {
  white-space: nowrap;
  padding: 5px;
  font-weight: 600;
  text-transform: capitalize;
}

.dataTable tbody tr td {
  border-top: 1px solid lightgray;
  padding: 10px;
  /* text-transform: capitalize; */
}

/* .dataTable tbody tr td:first-child > p {
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
} */

.username {
  min-width: 180px;
}

.dataTable tbody tr td:nth-child(9) {
  white-space: nowrap;
}

/* react suite */

.rs-uploader-trigger input[type="file"] {
  background-color: blue !important;
  width: 230px;
}

.rs-dropdown-menu {
  height: 38px;
  width: 100%;
  border-top: 1px solid lightgray;
  padding: 0 !important;
  border-radius: 0 0 3px 3px !important;
}

.rs-dropdown-menu a {
  height: 38px;
  font-size: 14px;
  border-radius: 0 0 4px 4px !important;
}

.rs-dropdown-menu a:hover {
  background-color: lightgray !important;
}

.rs-dropdown-menu svg {
  font-size: 17px;
  margin-right: 5px;
  margin-bottom: -3px;
}
.rs-uploader-file-item,
.rs-uploader-trigger-btn {
  margin: 10px 10px 10px 5px !important;
  border: 1px solid lightgray !important;
  box-shadow: 0 5px 15px 0 var(--shadow-grey) !important;
}

/* Rch Text Editor */

.RichTextEditor__root___2QXK- {
  margin: 15px 5px 0 5px !important;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 var(--shadow-grey) !important;
}
