.mainDiv {
  width: 250px;
}
.sidebar {
  width: 250px;
  background-color: var(--brown);
  height: calc(100vh - 70px);
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 1;
  box-shadow: 5px 5px 5px 0 rgba(163, 163, 163, 0.541);
  border-top: 1px solid var(--gray);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
}
.sidebar > section {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.sidebar > section > button {
  background-color: transparent;
  text-align: left;
  border-radius: 5px 0 0 5px;
  color: #ffffff;
  font-size: 14px;
  padding: 8px !important;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar > section > button > svg {
  font-size: 20px;
  margin-right: 7px;
}
.sidebar > section > button:hover {
  background-color: var(--lightgray);
  color: var(--brown);
  transform: translateX(10px) scale(1.09);
  transition: all 0.1s;
}
.active {
  border-radius: 5px 0 0 5px;
  background-color: var(--lightgray) !important;
  color: var(--brown) !important;
  transform: translateX(10px) scale(1.09) !important;
}
.appConfigSec {
  padding-top: 15px;
  margin-top: 5px !important;
  border-top: 1px solid white;
}
.appConfigSec > h6 {
  font-weight: 400;
  font-size: 13.5px;
  color: white !important;
  line-height: 17px;
  margin-bottom: 5px;
  letter-spacing: 0.2px;
}

/* scroll bar stylings */

/* width */
.sidebar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
.sidebar::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px 1px var(--gray);
  border-radius: 4px;
  border: 2px solid transparent;
}
/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: var(--lightgray);
  border: 1px solid #c2c3c3;
  border-radius: 10px;
}

/* media query */

@media only screen and (max-width: 768px) {
  .mainDiv {
    width: 70px;
  }
  .sidebar {
    width: 70px;
    padding: 0px;
  }
  .sidebar > section {
    margin-top: 10px;
  }
  .sidebar > section > button {
    text-align: center;
    font-size: 10px;
    padding: 5px !important;
    flex-direction: column;
  }
  .sidebar > section > button > svg,
  .sidebar > section > button > img {
    font-size: 22px;
    margin-right: 7px;
  }
  .sidebar > section > button:hover {
    background-color: var(--brown);
    color: white;
    transform: none;
    transition: none;
  }
  .active {
    transform: none !important;
  }
  .appConfigSec {
    display: none !important;
  }
}

/* .collapse {
  width: 70px !important;
}
.collapse > .sidebar {
  width: 70px !important;
  padding: 0px !important;
}
.collapse > .sidebar > section {
  margin-top: 10px !important;
}
.collapse > .sidebar > section > button {
  text-align: center !important;
  font-size: 10px !important;
  padding: 5px !important;
  flex-direction: column !important;
}
.collapse > .sidebar > section > button > svg,
.collapse > .sidebar > section > button > img {
  font-size: 22px !important;
  margin-right: 7px !important;
}
.collapse > .sidebar > section > button:hover {
  background-color: var(--brown) !important;
  color: white !important;
  transform: none !important;
  transition: none !important;
}
.active {
  transform: none !important;
}

.appConfigSec {
  display: none !important;
} */
