.mainDiv {
  min-height: calc(100vh - 56px);
  width: 100%;
  padding: 40px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.bigButtons {
  background-color: white;
  box-shadow: 0 2px 20px 0 #8f8f8fc9;
  width: 250px;
  padding: 15px;
  min-height: 300px;
  max-height: 400px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bigButtons > div > div:nth-child(3) {
  margin: 8px 0;
}
.bigButtons > div > p:nth-child(2),
.bigButtons > div > div:nth-child(3) > ol > li {
  overflow: hidden;
  text-overflow: ellipsis;
}
.bigButtons > div > div:nth-child(3) > ol {
  margin: 0%;
}
.btn {
  cursor: pointer;
  align-self: flex-end;
  margin-top: 10px;
  background-color: #725054;
  color: white;
  border-radius: 5px;
  padding: 7.5px 15px;
}
.btn:hover {
  background-color: #725054e7;
  transition: all 0.5ms;
}

/* donateDiv */

.donateDiv {
  padding: 40px;
}
.donateBtn {
  margin: 50px 0;
  padding: 25px;
  text-align: left;
}
.donateBtn > h5 {
  margin-bottom: 20px;
}
