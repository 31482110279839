.mainDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mainDiv h3 {
    font-size: 26px;
}

.mainDiv form {
    margin-top: 25px;
    display: flex;
    gap: 20px;
}

.mainDiv form > section:first-child {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}