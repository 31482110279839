.modal {
  background-color: white;
  box-shadow: 0 2px 20px 0 #8f8f8fc9;
  width: 100%;
  border-radius: 5px;
  padding: 25px;
  height: 100%;
}
.modal > h4 {
  margin-bottom: 15px;
}
.modal > form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.modal > form > div {
  width: 49%;
}
.headers {
  width: 100%;
}
.headers > div {
  display: flex;
  justify-content: space-between;
}
.headers > div > input {
  width: 49%;
}
.credentialField {
  background-color: #f8f8f8;
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
  font-size: 14px;
}
.modal > form > div > select {
  background-color: #f8f8f8;
  border: 1px solid #b6b6b6;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
  font-size: 14px;
}
.modal > form > div:last-child {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.modal > form > div:last-child > button:first-child {
  margin-right: 10px;
}
.btn {
  cursor: pointer;
  align-self: flex-end;
  background-color: #725054;
  color: white;
  border-radius: 5px;
  padding: 7.5px 15px;
}
.btn:hover {
  background-color: #725054e7;
  transition: all 0.5ms;
}

/* mdeia query */

@media only screen and (max-width: 624px) {
  .modal > form > div {
    width: 100%;
  }
}
