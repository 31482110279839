.main {
    width: 100%;
}

.main ol {
    margin-top: 10px;
    margin-left: 20px;
}

.main li {
    font-weight: 500;
    font-size: 16px;
}

.main td {
    text-align: center;
    white-space: nowrap;
}

.main td button {
    background-color: var(--brown);
    color: var(--lightgray);
    border-radius: 3px;
    border: 2px solid var(--brown);
}
.main td button:hover {
    transition: all 0.3s;
    background-color: transparent;
    color: var(--brown);
}