@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

:root {
  --brown: #735054;
  --gray: #cccccc;
  --lightgray: rgb(230, 230, 230);
  --cream: #fffdd0;
  --shadow-grey: rgb(170, 170, 170);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: var(--lightgray) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000 !important;
}

hr {
  border-width: 0.5px !important;
  border-color: #3f3f3f !important;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  background-color: #ffffff;
}

.collapse {
  visibility: visible;
}
