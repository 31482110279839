.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 110px);
}
.dashboard > h1 {
  color: var(--brown) !important;
  text-shadow: 0 5px 2px var(--gray);
  font-weight: 500;
}
