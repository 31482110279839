.LogoImage {
  width: 130px;
  height: 130px;
}
.Loader {
  z-index: 300;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(41, 41, 41, 0.664);
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddRemoveButton {
  margin: 5px;
  margin-top: 20px;
}
.dashboardBtn {
  color: black !important;
  padding: 13px !important;
  font-size: 13.3px !important;
  width: 160px;
}
.dashboardBtn:hover {
  transform: scale(1.1);
  transition: all 0.1s;
}
.Form {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  max-width: 350px;
  padding: 20px;
}
.FormInputs {
  height: 25px;
  margin: 10px;
}
.PopupScreenImage {
  width: 300px;
  height: 400px;
}
.PopupScreenMain {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}
.MainPageBannerImage {
  width: 250px;
}
.MainPageBannerMain {
  padding-top: 40px;
}
.HeaderButton {
  margin-right: 20px;
}
.HeaderButtonsMain {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}
.HeaderName {
  color: white;
}
.HeaderNameMain {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.HeaderMain {
  width: 100%;
  z-index: 150;
  display: flex;
  background-color: blue;
  flex-wrap: wrap;
}
.EventsNewsMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Events {
  margin: 25px;
  padding: 20px;
  border: 2px solid black;
}
.DeathNewsMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.DeathNews {
  text-align: left;
  padding: 10px;
  border: 2px solid black;
  margin: 10px;
}
.DeathNewsAddMain {
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.DeathNewsAddSubMain {
  padding: 20px;
  flex: 1;
}
.FeedbackMainHeading {
  text-align: center;
}
.FeedBackCardsMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.FeedbackCards {
  width: 250px;
  border: 2px solid black;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
  padding: 5px;
  margin: 10px;
}
.datatableWrapper {
  padding: 15px;
}
.checkbox {
  margin: 7px 0;
}
.checkbox > input {
  margin-right: 5px;
}
