.main {
  display: flex;
  height: calc(100vh - 70px);
}
.main > div:last-child {
  width: calc(100% - 250px);
  overflow: auto;
  padding: 20px;
}

/* media query */

@media only screen and (max-width: 768px) {
  .main > div:last-child {
    width: calc(100% - 70px);
    padding: 10px;
  }
}
